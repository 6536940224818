.carousel .control-dots {
    bottom: -29px !important;
    display: flex;
    justify-content: center;
}
.custom-event-slide .carousel .slider {
    height: 88px;
}
.event-slider img {
    object-fit: cover !important;
    object-position: center;
}
