.marquee {
  --gap: 0.25rem;
  position: relative;
  display: flex;
  overflow: hidden;
  user-select: none;
  gap: var(--gap);
}

.marquee__content {
  flex-shrink: 0;
  display: flex;
  justify-content: space-around;
  gap: var(--gap);
  min-width: 100%;
}

.marquee__content img {
  width: 107px;
  aspect-ratio: 107/48 ;
}

.marquee__item {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes scroll {
  from {
    transform: translateX(calc(-100% - var(--gap)));
  }
  to {
    transform: translateX(0);
  }
}

.enable-animation .marquee__content {
  animation: scroll 20s linear infinite;
}

.marquee:hover .marquee__content {
  animation-play-state: paused;
}


.thumbnail-scroller {
  width: 100%;
  overflow: hidden;
}

.slider-item {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slider-item img {
  width: 107px;
  object-fit: cover;
}

.slick-track {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.slick-slide {
  outline: none;
  display: flex;
  justify-content: center;
  width: 107px;
  aspect-ratio: 107/48;
}
